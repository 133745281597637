import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      {/* <MenuItem title='Dashboard Settings' to='/builder' /> */}
      {/* <MenuItem title='New User ' to='apps/user-management/' />
      <span className='bullet bullet-dot bg-success h-9px w-9px  translate-middle mt-6 start-50 animation-blink' /> */}
    </>
  )
}
