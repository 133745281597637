/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {
  StatisticsWidget5,
  CardsWidget7,
  CardsWidget20,
  CardsWidget17,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import {Link} from 'react-router-dom'
import readerContext from '../../../context/pin/readerContext'
import {Completed} from '../../modules/area/components/reader/Completed'
import {HealthCards} from '../HealthCards'
type IProps = {
  userinfo: any
  pending: any
  completed: any
  redo: any
}
const DashboardPage: FC<IProps> = ({userinfo, pending, completed, redo}) => (
  <>
    <div className='row g-5 g-xl-8'>
      <div className='col-6 col-md-6 col-lg-6 col-xl-6 col-xxl-2'>
        <Link to='/crafted/pages/reader/total-sites'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-white '
            svgIcon='/media/icons/duotune/maps/map002.svg'
            color='info'
            iconColor='white'
            title={userinfo?.totalSites?.length}
            description='Total Sites'
          />
        </Link>
      </div>
      <div className='col-6 col-md-6 col-lg-6 col-xl-6 col-xxl-2 '>
        <Link to='/crafted/pages/reader/pending'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-white'
            svgIcon='/media/icons/duotune/abstract/abs031.svg'
            color='primary'
            iconColor='white'
            title={pending}
            description='Pending'
          />
        </Link>
      </div>
      <div className='col-6 col-md-6 col-lg-6 col-xl-6 col-xxl-2'>
        <Link to='/crafted/pages/reader/completed'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-dark'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='secondary'
            iconColor='dark'
            title={completed}
            description='Completed'
          />
        </Link>
      </div>

      <div className='col-6 col-md-6 col-lg-6 col-xl-6 col-xxl-2'>
        <Link to='/crafted/pages/reader/redo'>
          <StatisticsWidget5
            className='h-md-40 mb-5 mb-xl-10 text-white'
            svgIcon='/media/icons/duotune/general/gen010.svg'
            color='success'
            iconColor='white'
            title={redo}
            description='Redo'
          />
        </Link>
      </div>
      <div className='col-xl-2 d-none d-sm-block'>
        <Link to='/apps/consumer-information/users'>
          <StatisticsWidget5
            className='h-md-40 mb-5 mb-xl-10 text-white'
            svgIcon='/media/icons/duotune/communication/com005.svg'
            color='dark'
            iconColor='white'
            title='0'
            description='Missing Video'
          />
        </Link>
      </div>
      <div className='col-xl-2 d-none d-sm-block'>
        <Link to='/crafted/pages/reader/connections'>
          <StatisticsWidget5
            className='h-md-40 mb-5 mb-xl-10 text-white'
            svgIcon='/media/icons/duotune//general/gen008.svg'
            color='warning'
            iconColor='white'
            title='200'
            description='Notifications'
          />
        </Link>
      </div>
    </div>
    {/* begin::Row */}
    <div className='row  mb-5 mb-xl-10'>
      {/* begin::Col */}
      <HealthCards className={''} />
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-8 g-5 g-xl-10 d-none d-sm-block'>
        <EngageWidget10 className='' />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapperReader: FC = () => {
  const {getUserStats, loginUserstats, getLoginUserData, loginUsersdata} = useContext(readerContext)
  useEffect(() => {
    getUserStats()
  }, [])
  useEffect(() => {
    getLoginUserData()
  }, [])

  const pending = loginUsersdata?.filter((item: any) => item.status === 'pending').length
  const completed = loginUsersdata?.filter((item: any) => item.status === 'completed').length
  const redo = loginUsersdata?.filter((item: any) => item.status === 'redo').length
  console.log(pending, 'pending')

  // console.log(loginUsersdata, 'loginUsersdata')

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage
        userinfo={loginUserstats}
        pending={pending}
        completed={completed}
        redo={redo}
      />
    </>
  )
}

export {DashboardWrapperReader}
