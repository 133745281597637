const HANDLE_CHANGE = 'HANDLE_CHANGE'
// const CLEAR_FILTERS = 'CLEAR_FILTERS'
const CLEAR_FILTERS_DISCO = 'CLEAR_FILTERS_DISCO'
const CLEAR_FILTERS_CIRCLE = 'CLEAR_FILTERS_CIRCLE'
const CLEAR_FILTERS_DIVISION = 'CLEAR_FILTERS_DIVISION'
const CLEAR_FILTERS_SUBDIVISION = 'CLEAR_FILTERS_SUBDIVISION'
const reducer = (state, action) => {
  if (action.type === HANDLE_CHANGE) {
    return {
      ...state,
      [action.payload.name]: action.payload.value,
    }
  }
  // if (action.type === CLEAR_FILTERS) {
  //   return {
  //     DISCO: '',
  //     Circle: '',
  //     Division: '',
  //     SubDivision: '',
  //   }
  // }
  if (action.type === CLEAR_FILTERS_DISCO) {
    return {
      ...state,
      DISCO: '',
      Circle: '',
      Division: '',
      SubDivision: '',
    }
  }
  if (action.type === CLEAR_FILTERS_CIRCLE) {
    return {
      ...state,
      Circle: '',
      Division: '',
      SubDivision: '',
    }
  }
  if (action.type === CLEAR_FILTERS_DIVISION) {
    return {
      ...state,
      Division: '',
      SubDivision: '',
    }
  }
  if (action.type === CLEAR_FILTERS_SUBDIVISION) {
    return {
      ...state,
      SubDivision: '',
    }
  }
}

export default reducer
