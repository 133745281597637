import readerContext from './readerContext'
import {useEffect, useReducer, useState} from 'react'
import axios from 'axios'
import reducer from './reducer'
const HANDLE_CHANGE = 'HANDLE_CHANGE'
const CLEAR_FILTERS_DISCO = 'CLEAR_FILTERS_DISCO'
const CLEAR_FILTERS_CIRCLE = 'CLEAR_FILTERS_CIRCLE'
const CLEAR_FILTERS_DIVISION = 'CLEAR_FILTERS_DIVISION'
const CLEAR_FILTERS_SUBDIVISION = 'CLEAR_FILTERS_SUBDIVISION'
const initialState = {
  DISCO: '',
  Circle: '',
  Division: '',
  SubDivision: '',
  searchSite: '',
}
const ReaderProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const API = 'http://localhost:5001'
  const token = localStorage.getItem('authToken')
  const [users, setusers] = useState([])
  const [loginUsersdata, setloginUsersdata] = useState([])
  const [loginUserstats, setLoginUserStats] = useState({})
  const [loading, setLoading] = useState(false)
  const [userData, setUser] = useState('')
  const [readerSiteData, setReaderSiteData] = useState({})
  const [uploadSiteData, setUploadSiteData] = useState({})
  const [singleHealthFormData, setSingleHealthFormData] = useState({})
  const [previousMonthSiteData, setPreviousMonthSiteData] = useState({})
  const [filterdata, setFilterData] = useState([])

  const handleChange = ({name, value}) => {
    dispatch({type: HANDLE_CHANGE, payload: {name, value}})
  }
  // const clearFilters = () => {
  //   dispatch({type: CLEAR_FILTERS})
  // }
  const clearFiltersDisco = () => {
    dispatch({type: CLEAR_FILTERS_DISCO})
  }
  const clearFiltersCircle = () => {
    dispatch({type: CLEAR_FILTERS_CIRCLE})
  }
  const clearFiltersDivision = () => {
    dispatch({type: CLEAR_FILTERS_DIVISION})
  }
  const clearFiltersSubDivision = () => {
    dispatch({type: CLEAR_FILTERS_SUBDIVISION})
  }

  const getFilterData = async () => {
    // API Call
    const {DISCO, Circle, Division, SubDivision, searchSite} = state
    let url = `${process.env.REACT_APP_API_URL}/api/v1/site/alldata?DISCO=${DISCO}&Circle=${Circle}&Division=${Division}&SubDivision=${SubDivision}`
    if (searchSite) {
      url = url + `&search=${searchSite}`
    }
    // const response = await axios.get('http://localhost:5001/api/v1/site/alldata')
    const response = await axios.get(url)
    // console.log(response.data.data.allData)
    setFilterData(response.data.data.result)
  }

  const getUserStats = async () => {
    // API Call

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/userStats`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      console.log(response.data.stats[0])
      setLoginUserStats(response.data.stats[0])
    } catch (error) {}
  }
  const updateUserStatus = async (id, data) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/users/updateUserStatus`,
        {userId: id, status: data},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      getallUser()
    } catch (err) {
      console.log('error is there')

      console.log(err)
    }
  }

  const getallUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/users/getAllUsers`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const alluser = await response.data

      setusers(alluser.data)
    } catch (err) {
      console.log(err)
    }
  }

  const getMe = async () => {
    // setLoading(true)
    const tk = localStorage.getItem('authToken')
    if (tk) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/getMe`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tk}`,
          },
        })

        if (response.status === 404) {
          localStorage.removeItem('authToken')
          setUser('')
          setLoading(false)
        } else if (response.status === 200) {
          const user = await response.data
          setUser(user)
          // setLoading(false)
          // return user
        }
      } catch (e) {
        setLoading(false)
        // localStorage.removeItem("authToken");
        // setUser('')
      }
    }
  }

  const getLoginUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/site/loginUserData`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const userData = await response.data

      setloginUsersdata(userData.loginUserData)
    } catch (err) {
      console.log(err)
    }
  }
  const getReaderSiteData = async (data) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/reader/getReaderSiteData/${data}`
      )
      const readerSiteData = await response.data[0]
      console.log(readerSiteData, 'readerSiteData')

      setReaderSiteData(readerSiteData)
    } catch (err) {
      console.log(err)
    }
  }
  const updateReaderSiteData = async (siteId, data) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/reader/uploadData/${siteId}`,
        data
      )
      console.log(response.data)
    } catch (err) {
      console.log(err)
    }
  }
  const updateUploadDatabySiteId = async (id, data, siteId) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/reader/uploadData/${id}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      updateStatus(siteId, {status: 'completed'})
      console.log(response.data)
    } catch (err) {
      console.log(err)
    }
  }
  const getUploadSiteData = async (siteId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/reader/uploadData/${siteId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const singleUplaodData = response.data
      setUploadSiteData(singleUplaodData)
    } catch (err) {
      console.log(err)
    }
  }
  const getPreviousMonthSiteData = async (siteId, Fromdate, Todate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/reader/getPreviousMonthSiteData/${siteId}`,
        {Fromdate, Todate},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      console.log(response.data.filterData)
      const singleUplaodData = response.data.filterData
      setPreviousMonthSiteData(singleUplaodData)
    } catch (err) {
      console.log(err)
    }
  }

  const uploadData = async (data, id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/reader/uploadData`,
        data
      )

      if (response.data) {
        updateStatus(id, {status: 'completed'})
      }
      return response.data
    } catch (err) {
      return err.response.data
    }
  }
  const uploadHealthForm = async (data, id) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/healthform`, data)

      if (response.data) {
        updateStatus(id, {formStatus: 'completed'})
      }
    } catch (err) {
      console.log(err)
    }
  }
  const getSingleHealthFormData = async (siteId, Fromdate, Todate) => {
    try {
      let response
      if (!Fromdate && !Todate) {
        response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/healthform/${siteId}`)
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/healthform/${siteId}`,
          {Fromdate, Todate}
        )
      }
      // const singleUplaodData = response.data[0]
      setSingleHealthFormData(response.data.singleHealthForm)
    } catch (err) {
      console.log(err)
    }
  }
  const updateHealthForm = async (siteId, data) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/healthform/${siteId}`,
        data
      )
      // const singleUplaodData = response.data[0]
    } catch (err) {
      console.log(err)
    }
  }

  const updateStatus = async (id, data) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/site/updateSite/${id}`,
        data
      )
      console.log(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <readerContext.Provider
      value={{
        getUserStats,
        getallUser,
        updateUserStatus,
        getMe,
        getLoginUserData,
        loginUsersdata,
        userData,
        loginUserstats,
        users,
        loading,
        getReaderSiteData,
        readerSiteData,
        updateReaderSiteData,
        uploadData,
        getUploadSiteData,
        uploadSiteData,
        updateUploadDatabySiteId,
        getPreviousMonthSiteData,
        previousMonthSiteData,
        getFilterData,
        filterdata,
        uploadHealthForm,
        getSingleHealthFormData,
        singleHealthFormData,
        updateHealthForm,
      }}
    >
      {props.children}
    </readerContext.Provider>
  )
}
export default ReaderProvider
