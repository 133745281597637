import './style.css'
import {Link, useNavigate} from 'react-router-dom'
// import { useDispatch, useSelector } from "react-redux";
// import Cookies from 'js-cookie'
import {Form, Formik} from 'formik'
import {useState} from 'react'
// import LoginInput from '../../components/inputs/loginInput'
import SearchAccount from './SearchAccount'
import SendEmail from './SendEmail'
import CodeVerification from './CodeVerification'
import ChangePassword from './ChangePassword'
export default function Reset() {
  // const {user} = useSelector((state) => ({...state}))
  // const dispatch = useDispatch();
  const navigate = useNavigate()
  const [visible, setVisible] = useState(0)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [conf_password, setConf_password] = useState('')
  const [error, setError] = useState('')
  const [userInfos, setUserInfos] = useState('')

  console.log(userInfos)
  return (
    <div className='reset'>
      <div className='reset_wrap'>
        {visible === 0 && (
          <SearchAccount
            email={email}
            setEmail={setEmail}
            error={error}
            setError={setError}
            setLoading={setLoading}
            setUserInfos={setUserInfos}
            setVisible={setVisible}
          />
        )}
        {visible === 1 && userInfos && (
          <SendEmail
            email={email}
            userInfos={userInfos}
            error={error}
            setError={setError}
            setLoading={setLoading}
            setUserInfos={setUserInfos}
            setVisible={setVisible}
          />
        )}
        {visible === 2 && (
          <CodeVerification
            // user={user}
            code={code}
            setCode={setCode}
            error={error}
            setError={setError}
            setLoading={setLoading}
            setVisible={setVisible}
            userInfos={userInfos}
          />
        )}
        {visible === 3 && (
          <ChangePassword
            password={password}
            conf_password={conf_password}
            setConf_password={setConf_password}
            setPassword={setPassword}
            error={error}
            setError={setError}
            setLoading={setLoading}
            setVisible={setVisible}
            userInfos={userInfos}
          />
        )}
      </div>
    </div>
  )
}
