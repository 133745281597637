import axios from 'axios'
import { Link } from 'react-router-dom'

export default function SendEmail({
  userInfos,
  email,
  error,
  setError,
  setVisible,
  setUserInfos,
  loading,
  setLoading,
}) {
  const sendEmail = async () => {
    try {
      setLoading(true)
      await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/users/sendResetPasswordCode`, {
        email,
      })
      setError('')
      setVisible(2)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(error.response.data.message)
    }
  }
  return (
    <div className='reset_form dynamic_height'>
      <div className='fs-1 fw-bold'>Reset Your Password</div>
      <div className='reset_grid'>
        <div className='reset_left'>
          {/* <div className='fs-6'>
            How do you want to receive the code to reset your password?
          </div> */}
          <label htmlFor='email' className='hover1'>
            <input type='radio' name='' id='email' checked readOnly />
            <div className='label_col'>
              <span>Send code via email</span>
              <span>{userInfos.email}</span>
            </div>
          </label>
        </div>
        {/* <div className='reset_right'>
          <img src={userInfos.picture} alt='' />
          <span>{userInfos.email}</span>
          <span>Facebook user</span>
        </div> */}
      </div>
      {error && (
        <div className='error_text' style={{ padding: '10px' }}>
          {error}
        </div>
      )}
      <div className='text-center flex'>
        <Link to='/login' className='btn btn-light-primary mx-2  mb-2'>
          Not You ?
        </Link>
        <button
          onClick={() => {
            sendEmail()
          }}
          className='btn btn-lg btn-primary flex mb-2'
        >
          Continue
        </button>
      </div>
    </div>
  )
}
