/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {
  StatisticsWidget5,
  CardsWidget7,
  CardsWidget20,
  CardsWidget17,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import {Link} from 'react-router-dom'
import axios from 'axios'
import pinContext from '../../../context/pin/pinContext'
interface IMyProps {
  data?: any
  users?: any
}
const DashboardPage: FC<IMyProps> = ({data, users}: IMyProps) => (
  <>
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <Link to='/crafted/pages/area/total-sites'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-white '
            svgIcon='/media/icons/duotune/maps/map002.svg'
            color='info'
            iconColor='white'
            title={data?.SiteIdData}
            description='Total Sites'
          />
        </Link>
      </div>
      <div className='col-xl-4'>
        <Link to='/crafted/pages/area/assign-sites'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-white '
            svgIcon='/media/icons/duotune/maps/map002.svg'
            color='primary'
            iconColor='white'
            title={data?.assign}
            description='Assigned Sites'
          />
        </Link>
      </div>
      <div className='col-xl-4'>
        <Link to='/crafted/pages/area/unassign-sites'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-white '
            svgIcon='/media/icons/duotune/maps/map002.svg'
            color='warning'
            iconColor='white'
            title={data?.Unassign}
            description='Unassigned Sites'
          />
        </Link>
      </div>
      <div className='col-xl-4'>
        <Link to='/crafted/pages/area/pending-sites'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-dark'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='secondary'
            iconColor='dark'
            title={data?.pending}
            description='Pending Sites'
          />
        </Link>
      </div>

      <div className='col-xl-4 '>
        <Link to='/crafted/pages/area/completed-sites'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-white'
            svgIcon='/media/icons/duotune/abstract/abs031.svg'
            color='success'
            iconColor='white'
            title={data?.completed}
            description='Completed Sites'
          />
        </Link>
      </div>
      <div className='col-xl-4 '>
        <Link to='/crafted/pages/area/redo-sites'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-white'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='danger'
            iconColor='white'
            title={data?.redo}
            description='Redo Sites'
          />
        </Link>
      </div>
    </div>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-2 mb-md-5 mb-xl-10'>
        <Link to='/crafted/pages/area/total-divisions'>
          <StatisticsWidget5
            className='h-md-40 mb-5 mb-xl-10 text-dark bg-opacity-50'
            svgIcon='/media/icons/duotune/general/gen010.svg'
            color='success'
            iconColor='dark'
            title={data.DivisionData?.length}
            description='Total Divisions'
          />
        </Link>
        <Link to='/apps/consumer-information/users'>
          <StatisticsWidget5
            className='h-md-40 mb-5 mb-xl-10 text-white bg-opacity-75'
            svgIcon='/media/icons/duotune/communication/com005.svg'
            color='dark'
            iconColor='white'
            title={data?.ClientData?.length}
            description='Total Consumers'
          />
        </Link>
      </div>
      {/* end::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-2 mb-md-5 mb-xl-10'>
        <Link to='/crafted/pages/area/total-circles'>
          <StatisticsWidget5
            className='h-md-40 mb-5 mb-xl-10 text-dark bg-opacity-50'
            svgIcon='/media/icons/duotune/abstract/abs031.svg'
            color='primary'
            iconColor='dark'
            title={data?.CircleData?.length}
            description='Total Circles'
          />
        </Link>
        <Link to='/crafted/pages/area/total-disco'>
          <StatisticsWidget5
            className='h-md-40 mb-5 mb-xl-10 text-dark bg-opacity-75'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='secondary'
            iconColor='dark'
            title={data?.DISCOData?.length}
            description='Total Disco'
          />
        </Link>
      </div>

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-2 mb-md-5 mb-xl-10'>
        <Link to='/crafted/pages/area/sub-divisions'>
          <StatisticsWidget5
            className='h-md-40 mb-5 mb-xl-10 text-dark bg-opacity-50'
            svgIcon='/media/icons/duotune//general/gen008.svg'
            color='warning'
            iconColor='dark'
            title={data?.SubDivisionData?.length}
            description='Sub Divisions'
          />
        </Link>
        <Link to='/crafted/pages/area/meter-reader'>
          <StatisticsWidget5
            className='h-md-40 mb-5 mb-xl-10 text-dark bg-opacity-75'
            svgIcon='/media/icons/duotune/communication/com006.svg'
            color='info'

            iconColor='white'
            title={users?.filter((user: any) => user.role !== 'admin').length}
            description='Total Readers'

          />
        </Link>
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-6'>
        <EngageWidget10 className='h-md-39' />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const {getData, mydata, getallUser, users} = useContext(pinContext)
  // const [mydata, setData] = useState({})
  // const getData = async () => {
  //   // API Call

  //   const response = await axios.get('http://localhost:5001/api/v1/site')
  //   console.log(response.data.data)
  //   setData(response.data.data)
  //   console.log(mydata, 'mydata')
  // }
  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    getallUser()
  }, [])

  interface Props {
    data: any
    users: any
  }
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage data={mydata} users={users} />
    </>
  )
}

export {DashboardWrapper}
