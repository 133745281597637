/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuReader = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Regions Information'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/communication/com001.svg'
      >
        
          <SidebarMenuItem to='/crafted/pages/area/overview' title='All Region' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/area/projects' title='Discos' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/area/campaigns'
            title='Circles'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/area/documents'
            title='Divisions'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/area/connections'
            title='Sub Divisions'
            hasBullet={true}
            />
        

      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItem
        to='/apps/meters-information'
        icon='/media/icons/duotune/electronics/elc008.svg'
        title='Meter Management'
        fontIcon='bi-app-indicator'
      /> */}

      {/* <SidebarMenuItem
        to='/apps/user-management/'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Users Mangement'
        fontIcon='bi-layers'
      /> */}
      {/* <SidebarMenuItem
        to='apps/upload-reading/'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Upload Reading'
        fontIcon='bi-layers'
      /> */}
      {/* <SidebarMenuItem
        to='/physical-form'
        icon='/media/icons/duotune/general/gen049.svg'
        title='Physical Form'
        fontIcon='bi-layers'
      /> */}
      {/* <SidebarMenuItem
        to='/crafted/pages/reader/form-pending'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Physical Form'
        fontIcon='bi-layers'
      /> */}

      <SidebarMenuItem
        to=''
        icon='/media/icons/duotune/maps/map002.svg'
        title='Site Map'
        fontIcon='bi-layers'
      />
      {/* <SidebarMenuItem
        to=''
        icon='/media/icons/duotune/files/fil012.svg'
        title='Record Management'
        fontIcon='bi-layers'
      /> */}

      <SidebarMenuItem
        to='crafted/pages/reader/completed'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Meter Reading Reports'
        fontIcon='bi-layers'
      />

      {/* <SidebarMenuItem
        to=''
        icon='/media/icons/duotune/files/fil011.svg'
        title='Backup'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to=''
        icon='/media/icons/duotune/general/gen007.svg'
        title='Notification'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to=''
        icon='/media/icons/duotune/technology/teh001.svg'
        title='Report a Bug'
        fontIcon='bi-layers'
      /> */}
    </>
  )
}

export { SidebarMenuReader }
