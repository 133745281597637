import React, {useContext, useEffect, useState} from 'react'
// import {UsersListFilter} from './ListFilter'
import {Link} from 'react-router-dom'
import {useFormik, yupToFormErrors} from 'formik'
import {StatisticsWidget5} from '../../_metronic/partials/widgets'
import {isNotEmpty, KTCard, KTSVG} from '../../_metronic/helpers'
import pinContext from '../../context/pin/pinContext'
import clsx from 'clsx'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
// import {AssignFilter} from './AssignFilter'
import {string} from 'yup/lib/locale'
import readerContext from '../../context/pin/readerContext'
// import DateRangeComp from './DateRangeComp'
type Props = {
  className: string
  isUserLoading?: boolean
}

const HealthCards: React.FC<Props> = ({className, isUserLoading}) => {
  // const {getData, mydata, detailData, alldata, updateSite, updateStatus} = useContext(pinContext)
  const {
    getLoginUserData,
    loginUsersdata,
    updateStatus,
    updateHealthForm,
    getSingleHealthFormData,
    singleHealthFormData,
  } = useContext(readerContext)
  useEffect(() => {
    getLoginUserData()
  }, [])

  return (
    <>
      <div className='col-12 col-xl-4 '>
        <Link to='/crafted/pages/reader/form-pending'>
          <StatisticsWidget5
            className='card-xl-stretch mb-3 mb-xl-8 text-dark bg-opacity-50'
            svgIcon='/media/icons/duotune/abstract/abs031.svg'
            color='primary'
            iconColor='dark'
            title={
              loginUsersdata.filter(
                (item: any) => item.FDCCode !== 'none' && item.formStatus === 'pending'
              ).length
            }
            description='Pending Form'
          />
        </Link>

        <Link to='/crafted/pages/reader/form-prioritized'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-dark bg-opacity-75'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='danger'
            iconColor='dark'
            title={
              loginUsersdata.filter(
                (item: any) => item.FDCCode !== 'none' && item.formStatus === 'prioritized'
              ).length
            }
            description='Prioritized Form'
          />
        </Link>

        <Link to='/crafted/pages/reader/form-completed'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-dark bg-opacity-75'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='success'
            iconColor='dark'
            title={
              loginUsersdata.filter(
                (item: any) => item.FDCCode !== 'none' && item.formStatus === 'completed'
              ).length
            }
            description='Completed Form'
          />
        </Link>
      </div>
    </>
  )
}
export {HealthCards}
