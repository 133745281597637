import { lazy, FC, Suspense, useContext, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import pinContext from '../../context/pin/pinContext'
import { DashboardWrapperReader } from '../pages/dashboard/DashboardWrapperReader'

const PrivateRoutes = () => {
  const { getMe, userData } = useContext(pinContext)
  useEffect(() => {
    getMe()
  }, [])

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AreaPage = lazy(() => import('../modules/area/AreaPage'))
  const AreaPageReader = lazy(() => import('../modules/area/AreaPageReader'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const MetersPage = lazy(() => import('../modules/apps/meters-information/UsersPage'))
  const Consumer = lazy(() => import('../modules/apps/consumer-information/UsersPage'))
  const Approval = lazy(() => import('../modules/approval/ProfilePage'))
  const UploadReading = lazy(() => import('../modules/apps/upload-reading/UsersPage'))
  const MasterFile = lazy(() => import('../modules/apps/master-file/MasterPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        {userData.role === 'admin' ? (
          <Route path='dashboard' element={<DashboardWrapper />} />
        ) : (
          <Route path='dashboard' element={<DashboardWrapperReader />} />
        )}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/area/*'
          element={
            <SuspensedView>
              <AreaPage className={''} />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/reader/*'
          element={
            <SuspensedView>
              <AreaPageReader className={''} />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/approval/*'
          element={
            <SuspensedView>
              <Approval />
            </SuspensedView>
          }
        />
        <Route
          path='apps/meters-information/*'
          element={
            <SuspensedView>
              <MetersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/master-file/*'
          element={
            <SuspensedView>
              <MasterFile />
            </SuspensedView>
          }
        />
        <Route
          path='apps/consumer-information/*'
          element={
            <SuspensedView>
              <Consumer />
            </SuspensedView>
          }
        />
        <Route
          path='apps/upload-reading/*'
          element={
            <SuspensedView>
              <UploadReading />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
