/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

const Dropdown2: FC = () => {
  return (
    <div
      className='menu menu-sub mt- menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
      data-kt-menu='true'
    >
      

      <div className='separator mb-3 opacity-75'></div>
      <div className='menu-item px-3'>
        <a href='#' className='menu-link px-3'>
        Export as Excel
        </a>
      </div>
      <div className='menu-item px-3'>
        <a href='#' className='menu-link px-3'>
        Export as CVS
        </a>
      </div>

      <div className='menu-item px-3'>
        <a href='#' className='menu-link px-3'>
        Export as PDF
        </a>
      </div>


     

      <div className='separator mt-3 opacity-75'></div>

     
    </div>
  )
}

export {Dropdown2}
