// import {
//   FC,
//   useState,
//   useEffect,
//   createContext,
//   useContext,
//   useRef,
//   Dispatch,
//   SetStateAction,
// } from 'react'
// import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
// import {AuthModel, UserModel} from './_models'
// import * as authHelper from './AuthHelpers'
// import {getUserByToken} from './_requests'
// import {WithChildren} from '../../../../_metronic/helpers'

// type AuthContextProps = {
//   auth: any | undefined
//   saveAuth: (auth: any | undefined) => void
//   currentUser: any | undefined
//   setCurrentUser: Dispatch<SetStateAction<any | undefined>>
//   logout: () => void
// }

// const initAuthContextPropsState = {
//   auth: authHelper.getAuth(),
//   saveAuth: () => {},
//   currentUser: undefined,
//   setCurrentUser: () => {},
//   logout: () => {},
// }

// const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

// const useAuth = () => {
//   return useContext(AuthContext)
// }

// const AuthProvider: FC<WithChildren> = ({children}) => {
//   const [auth, setAuth] = useState<any | undefined>(authHelper.getAuth())
//   const [currentUser, setCurrentUser] = useState<any | undefined>()
//   const saveAuth = (auth: any | undefined) => {
//     setAuth(auth)
//     if (auth) {
//       authHelper.setAuth(auth.token)
//     } else {
//       authHelper.removeAuth()
//     }
//   }

//   const logout = () => {
//     saveAuth(undefined)
//     setCurrentUser(undefined)
//   }

//   return (
//     <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
//       {children}
//     </AuthContext.Provider>
//   )
// }

// const AuthInit: FC<WithChildren> = ({children}) => {
//   const {auth, logout, setCurrentUser} = useAuth()
//   const didRequest = useRef(false)
//   const [showSplashScreen, setShowSplashScreen] = useState(true)
//   // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
//   useEffect(() => {
//     const requestUser = async (apiToken: string) => {
//       try {
//         if (!didRequest.current) {
//           const {data} = await getUserByToken(apiToken)
//           if (data) {
//             setCurrentUser(data)
//           }
//         }
//       } catch (error) {
//         console.error(error)
//         if (!didRequest.current) {
//           logout()
//         }
//       } finally {
//         setShowSplashScreen(false)
//       }

//       return () => (didRequest.current = true)
//     }

//     if (auth && auth.api_token) {
//       requestUser(auth.api_token)
//     } else {
//       logout()
//       setShowSplashScreen(false)
//     }
//     // eslint-disable-next-line
//   }, [])

//   return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
// }

// export {AuthProvider, AuthInit, useAuth}

import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: any | undefined
  saveAuth: (auth?: any | undefined) => void
  currentUser: any | undefined
  setCurrentUser: Dispatch<SetStateAction<any | undefined>>
  logout: () => void
  forceLogout: () => void
  loading: boolean
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  forceLogout: () => {},
  loading: true,
}

export const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<any | undefined>()
  const [loading, setLoading] = useState<boolean>(true)
  const [currentUser, setCurrentUser] = useState<any | undefined>()
  const saveAuth = async (authDefined: any | undefined) => {
    const tk = (await authHelper.getAuth()) ?? authDefined
    setAuth(tk)
    if (tk) {
      authHelper.setAuth(tk)
      setLoading(false)
    } else {
      setLoading(false)
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }
  const forceLogout = async () => {
    await localStorage.removeItem(authHelper.AUTH_LOCAL_STORAGE_KEY)
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider
      value={{auth, saveAuth, currentUser, setCurrentUser, logout, loading, forceLogout}}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
