/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='apps/master-file'
        icon='/media/icons/duotune/art/art002.svg'
        title='Master File'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title=''
        fontIcon='bi-archive'
        icon='/media/icons/duotune/communication/com001.svg'
      >
        <SidebarMenuItem to='/crafted/pages/area/total-sites' title='All Region' hasBullet={true} />
        <SidebarMenuItem to='/crafted/pages/area/total-disco' title='Discos' hasBullet={true} />
        <SidebarMenuItem to='/crafted/pages/area/total-circles' title='Circles' hasBullet={true} />
        <SidebarMenuItem
          to='/crafted/pages/area/total-divisions'
          title='Divisions'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/crafted/pages/area/sub-divisions'
          title='Sub Divisions'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItem
        to='/apps/meters-information'
        icon='/media/icons/duotune/electronics/elc008.svg'
        title='Site Management'
        fontIcon='bi-app-indicator'
      /> */}
      <SidebarMenuItem
        to='crafted/pages/area/meter-reader'
        icon='/media/icons/duotune/electronics/elc008.svg'
        title='Readers status'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='crafted/pages/area/reader-profiles'
        icon='/media/icons/duotune/electronics/elc008.svg'
        title='Readers Profiles'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='crafted/pages/approval/overview'
        icon='/media/icons/duotune/electronics/elc008.svg'
        title='User Approval'
        fontIcon='bi-app-indicator'
      />

      {/* <SidebarMenuItem
        to='/apps/user-management/'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Users Mangement'
        fontIcon='bi-layers'
      /> */}
      <SidebarMenuItem
        to='/crafted/pages/area/upload-reading'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Uploaded Readings'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/crafted/pages/area/form-pending'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Physical Form'
        fontIcon='bi-layers'
      />
      {/* <SidebarMenuItem
        to='/crafted/pages/area/form-all'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Physical Form Record'
        fontIcon='bi-layers'
      /> */}
      {/* <SidebarMenuItem
        to='/apps/consumer-information/'
        icon='/media/icons/duotune/general/gen049.svg'
        title='Consumer Mangement'
        fontIcon='bi-layers'
      /> */}

      {/* <SidebarMenuItem
        to=''
        icon='/media/icons/duotune/maps/map002.svg'
        title='Site Map'
        fontIcon='bi-layers'
      /> */}
      {/* <SidebarMenuItem
        to=''
        icon='/media/icons/duotune/files/fil012.svg'
        title='Record Management'
        fontIcon='bi-layers'
      /> */}

      {/* <SidebarMenuItem
        to=''
        icon='/media/icons/duotune/general/gen019.svg'
        title='Meter Reading Reports'
        fontIcon='bi-layers'
      /> */}

      {/* <SidebarMenuItem
        to=''
        icon='/media/icons/duotune/files/fil011.svg'
        title='Backup'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to=''
        icon='/media/icons/duotune/general/gen007.svg'
        title='Notification'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to=''
        icon='/media/icons/duotune/technology/teh001.svg'
        title='Report a Bug'
        fontIcon='bi-layers'
      /> */}
    </>
  )
}

export { SidebarMenuMain }
