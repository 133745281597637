import pinContext from './pinContext'
import {useState} from 'react'
import axios from 'axios'

const PinProvider = (props) => {
  //copy
  const token = localStorage.getItem('authToken')
  const [users, setusers] = useState([])
  const [allUpload, setAllUpload] = useState([])
  const [allFormData, setAllFormData] = useState([])
  // const [userstats, setUserStats] = useState({})
  const [loading, setLoading] = useState(false)
  const [userData, setUser] = useState('')
  const [singleHealthFormData, setSingleHealthFormData] = useState({})
  const [singleReaderData, setSingleReaderData] = useState([])

  //me
  const [mydata, setData] = useState({})
  const [alldata, setallData] = useState([])
  // const [token, settoken] = useState('')

  const [stats, setStats] = useState([])

  const getData = async () => {
    // API Call

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/site`)
    setData(response.data.data)
  }

  const detailData = async () => {
    // API Call
    let url = `${process.env.REACT_APP_API_URL}/api/v1/site/alldata`
    const response = await axios.get(url)
    setallData(response.data.data.result)
  }
  const getStatsData = async () => {
    // API Call
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/site/allStats`)
    setStats(response.data.data.stats)

    console.log(stats, 'stats data of mine')
  }

  const updateUserStatus = async (userId, status) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/users/updateUserStatus`,
        {userId, status},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      getallUser()
    } catch (err) {
      console.log(err)
    }
  }

  const getallUser = async () => {
    const tk = localStorage.getItem('authToken')
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/users/getAllUsers`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tk}`,
          },
        }
      )
      const alluser = response.data

      setusers(alluser.users)
    } catch (err) {
      console.log(err)
    }
  }

  const getMe = async () => {
    setLoading(true)
    const tk = localStorage.getItem('authToken')
    if (tk) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/getMe`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tk}`,
          },
        })

        if (response.status === 404) {
          localStorage.removeItem('authToken')
          setUser('')
          setLoading(false)
        } else if (response.status === 200) {
          const user = await response.data
          setUser(user)
          setLoading(false)
          return user
        }
      } catch (e) {
        setLoading(false)
        // localStorage.removeItem("authToken");
        // setUser('')
      }
    }
  }
  const updateRecord = async (site, fdcCode) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/site/updateMany/${fdcCode}`,
        site
      )
    } catch (err) {
      console.log(err)
    }
  }
  const downLoadData = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/reader/exportData`)
    } catch (err) {
      console.log(err)
    }
  }

  const getallUploadData = async (Fromdate, Todate) => {
    console.log(Fromdate, Todate)
    const tk = localStorage.getItem('authToken')
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/reader/getuploadDataByRange`,
        {Fromdate, Todate},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tk}`,
          },
        }
      )
      const allUploadsdata = response.data.upload

      setAllUpload(allUploadsdata)
    } catch (err) {
      console.log(err)
    }
  }
  const getallHealthFormByMonth = async (Fromdate, Todate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/healthform/monthWise`,
        {Fromdate, Todate}
      )
      const allHealthFormData = response.data.healthForm

      setAllFormData(allHealthFormData)
    } catch (err) {
      console.log(err)
    }
  }

  const deleteSite = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/site/deleteSite/${id}`
      )
    } catch (error) {
      console.log(error)
    }
  }
  const updateStatus = async (id, data) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/site/updateSite/${id}`,
        data
      )
    } catch (err) {
      console.log(err)
    }
  }
  // ?page=${page}&status=${searchStatus}&jobType=${searchType}&sort=${sort}
  const getSingleHealthFormData = async (siteId, Fromdate, Todate) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/healthform/admin/${siteId}`
      )
      setSingleHealthFormData(response.data.singleHealthForm)
    } catch (err) {
      console.log(err)
    }
  }
  const createSite = async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/site`, data)
    } catch (err) {
      console.log(err)
    }
  }

  const getReaderData = async (code) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/site//readerStatus/${code}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )

      setSingleReaderData(response.data.readerData)
    } catch (err) {
      console.log(err)
    }
  }
  const deleteUser = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/users/deleteUser/${id}`
      )
      // return response.data
    } catch (error) {
      console.log(error)
    }
  }
  const updateUser = async (id, val) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/users/updateUser/${id}`,
        val
      )
      console.log(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <pinContext.Provider
      value={{
        getData,
        detailData,
        getStatsData,
        // getUserStats,
        getallUser,
        updateUserStatus,
        getMe,
        userData,
        users,
        loading,
        stats,
        mydata,
        alldata,
        updateRecord,
        getallUploadData,
        allUpload,
        downLoadData,
        deleteSite,
        updateStatus,
        getSingleHealthFormData,
        singleHealthFormData,
        createSite,
        getReaderData,
        singleReaderData,
        getallHealthFormByMonth,
        allFormData,
        deleteUser,
        updateUser,
      }}
    >
      {props.children}
    </pinContext.Provider>
  )
}
export default PinProvider
