import { Form, Formik } from 'formik'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import LoginInput from './loginInput'
import * as Yup from 'yup'
import axios from 'axios'
export default function SearchAccount({
  email,
  setEmail,
  error,
  setError,
  setLoading,
  setUserInfos,
  setVisible,
}) {
  const validateEmail = Yup.object({
    email: Yup.string()
      .required('Email is required.')
      .email('Must be a valid email address.')
      .max(50, "Email address can't be more than 50 characters."),
  })
  const handleSearch = async () => {
    try {
      setLoading(true)

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/users/findUser`, {
        email,
      })
      setUserInfos(data)
      setVisible(1)
      setError('')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(error.response.data.message)
    }
  }
  return (
    <div className='reset_form'>
      <div className='reset_form_header'>Find Your Account</div>
      <div className='reset_form_text'>
        Please enter your email address to search for your account.
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          email,
        }}
        validationSchema={validateEmail}
        onSubmit={() => {
          handleSearch()
        }}
      >
        {(formik) => (
          <Form>
            <LoginInput
              type='text'
              name='email'
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email address or phone number'
            />
            {error && <div className='error_text text-danger'>{error}</div>}
            <div className='reset_form_btns'>
              <Link to='/login' className='btn btn-lg btn-light-primary  mb-2'>
                Cancel
              </Link>
              <button type='submit' className='btn btn-lg btn-primary flex mb-2'>
                Search
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
