import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import ReaderProvider from '../context/pin/PinReader'
import PinProvider from '../context/pin/PinState'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <PinProvider>
        <ReaderProvider>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </ReaderProvider>
      </PinProvider>
    </Suspense>
  )
}

export {App}
